<template>
  <v-card :loading="loadingCard" color="transparent" elevation="0">
    <h2 class="mb-4">Invoice Detail</h2>
    <v-row class="d-flex flex-row-reverse">
      <v-col cols="6" class="d-flex justify-end">
        <v-btn @click="downloadPdf" color="primary"> Download </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col md="8">
            <v-card>
              <v-card-title>Billed To:</v-card-title>
              <v-card-text>
                <strong>{{ invoiceData.header.bill_to_name }}</strong>
                <p>
                  {{ invoiceData.header.bill_to_address }} <br />
                  {{ invoiceData.header.bill_to_address_2 }}<br /><br />
                  <strong>Contract & PO</strong>
                  {{ invoiceData.header.contract_no_ }}<br />
                  {{ invoiceData.header.po_no_ }}<br />
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4">
            <v-card>
              <v-card-text>
                <h2>{{ invoiceData.header.no_ }}</h2>
                <p>
                  <strong>{{ invoiceData.header.due_date }}</strong><br>
                  {{ invoiceData.campaign.description }} <br>
                  {{ invoiceData.campaign.area }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Items:</v-card-title>
          <v-data-table :items="invoiceData.detail" :headers="datatableColumns" :loading="loadingCard" dense>
            <template v-slot:item.quantity="{ item }">{{ numberFormat(item.quantity, 2) }}</template>
            <template v-slot:item.amount="{ item }">{{ numberFormat(item.amount, 2) }}</template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex flex-row-reverse">
      <v-col md="4">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="text-label">Total:</v-col>
              <v-col class="text-right text-value">{{ numberFormat(total, 2) }}</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col class="text-label">VAT:</v-col>
              <v-col class="text-right text-value">{{ numberFormat(totalInclVAT - total, 2) }}</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col class="text-label">Nett Total:</v-col>
              <v-col class="text-right text-value">{{ numberFormat(totalInclVAT, 2) }}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row class="d-flex flex-row-reverse">
      <v-col cols="6" class="d-flex justify-end">
        <v-btn @click="downloadPdf" color="primary"> Download </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  metaInfo: {
    title: 'Invoice Detail',
  },
  components: {},
  data: () => ({
    loadingCard: false,
    invoiceNo: 'loadingCard',
    invoiceData: {
      header: {},
      detail: [],
      campaign: {},
    },
    datatableColumns: [
      { text: 'Item No.', value: 'no_' },
      { text: 'Description', value: 'description' },
      { text: 'Quantity', value: 'quantity', align: 'end' },
      { text: 'Amount Excl. VAT', value: 'amount', align: 'end' },
    ],
    total:0,
    totalInclVAT:0,
  }),

  computed:{
  },

  methods: {
    async getData() {
      this.loadingCard = true

      var params = {
        invoice_no_: this.invoiceNo,
      }
      this.calcTotal();
      await this.$axios
        .get('invoice/detail', {
          params: params,
          cacheConfig: true,
        })
        .then(res => {
          if (res.status) {
            var resData = res.data
            if (resData.status == 'success') {
              this.invoiceData = resData.data
            }
          }
          this.loadingCard = false
          this.calcTotal();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.$awn.alert('Internal Server Error')
          this.loadingCard = false
          this.calcTotal();
        })
    },
    async downloadPdf() {
      let ivNo = this.invoiceData.header.invoice_no_
      let params = {
        no_: ivNo,
      }
      this.$store.state.showLoadingDialog = true
      await this.$axios
        .get('documents/invoice', {
          params: params,
          responseType: 'blob',
          cacheConfig: true,
        })
        .then(response => {
          this.$store.state.showLoadingDialog = false
          // console.log(response.data)
          if (response.data.type == 'application/json') {
            this.$awn.alert('File not found.')
          } else if (response.data.type == 'application/octet-stream') {
            const file = new Blob([response.data], { type: 'application/pdf' })
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file)
            //Open the URL on new Window
            window.open(fileURL)
          } else {
            this.$awn.alert('File not found.')
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.$store.state.showLoadingDialog = false
          // console.log(error)
        })
    },
    calcTotal(){
      this.total = 0
      this.totalInclVAT = 0
      this.invoiceData.detail.forEach(item => {
        this.total = this.total + parseFloat(item.amount)
        this.totalInclVAT = this.totalInclVAT + parseFloat(item.amount_including_vat)
      });
    },
  },
  mounted() {
    this.$store.state.showBackButton = true
    this.setTitle('Invoice Detail')

    this.invoiceNo = this.$route.query.no_
    this.getData()
  },
}
</script>

<style>
.text-label {
  font-size: 18px;
  font-weight: bold;
}
.text-value {
  font-size: 18px;
}
</style>